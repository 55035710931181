import dataConversor from "./dataConversionScript";
import data from "./investorProfileData.json";
import _ from "lodash";

const SET_INVESTOR_LIST = "SET_INVESTOR_LIST";
const SET_CURRENT_INVESTOR = "SET_CURRENT_INVESTOR";
const SET_FILTERS = "SET_FILTERS";
const SET_FILTER_OPEN = "SET_FILTER_OPEN";
const CLEANUP = "CLEANUP";

const state = {
  investorList: [],
  currentInvestor: {},
  filters: null,
  isFilterOpen: false,
};

const mutations = {
  [SET_INVESTOR_LIST](state, payload) {
    state.investorList = payload;
  },
  [SET_CURRENT_INVESTOR](state, payload) {
    state.currentInvestor = payload;
  },
  [SET_FILTERS](state, payload) {
    state.filters = payload;
  },
  [SET_FILTER_OPEN](state, isOpen) {
    state.isFilterOpen = isOpen;
  },
  [CLEANUP](state) {
    state.filters = null;
    state.isFilterOpen = false;
  },
};

const actions = {
  setInvestorList({ commit }) {
    const investorsList = dataConversor(data);
    commit(SET_INVESTOR_LIST, investorsList);
  },
  setCurrentInvestor({ commit, state }, payload) {
    const uncodedName = decodeURI(payload);
    const item = state.investorList.filter(
      (investor) => investor.name === uncodedName
    );
    commit(SET_CURRENT_INVESTOR, item[0]);
  },
  setFilters({ commit }, payload) {
    commit(SET_FILTERS, payload);
  },
  openFilter({ commit }, payload) {
    commit(SET_FILTER_OPEN, payload);
  },
  clearAll({ commit }) {
    commit(CLEANUP);
  },
};

const getters = {
  getInvestorList: (state) => {
    const filters = state.filters;
    const investorList = state.investorList;
    if (!filters) {
      return investorList;
    }

    let filteredList = investorList;
    for (let ii in filters) {
      if (typeof filters[ii] === "string") {
        filteredList = filteredList.filter((item) => {
          if (Object.hasOwnProperty.call(item, ii)) {
            if (typeof filters[ii] === "string") {
              const filter = filters[ii].toLowerCase();
              const element = item[ii].toLowerCase();

              return element.includes(filter);
            }
          }
        });
      }
      if (Array.isArray(filters[ii])) {
        filteredList = filteredList.filter((item) => {
          let isEqual = true;
          filters[ii].forEach((element) => {
            if (!item[ii].includes(element)) {
              isEqual = false;
            }
          });
          return isEqual;
        });
      }
    }

    return filteredList;
  },
  getCurrentInvestor: (state) => state.currentInvestor,
  getFilters: (state) => state.filters,
  getTotalInvestors: (state) => state.investorList,
  isFilterOpen: (state) => state.isFilterOpen,
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
};
