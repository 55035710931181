import _ from "lodash";
import axios from "@/core/services/axios";

const SET_FILTERS = "SET_FILTERS";
const SET_DEALS = "SET_DEALS";
const SET_PAGINATION = "SET_PAGINATION";
const SET_SELECTED_DEAL = "SET_SELECTED_DEAL";
const SET_PLATFORM = "SET_PLATFORM";
const SET_DEAL_TYPE = "SET_DEAL_TYPE";
const SET_FILTER_OPEN = "SET_FILTER_OPEN";
const CLEANUP = "CLEANUP";
const SET_TOTAL_NUMBER_OF_DEALS = "SET_TOTAL_NUMBER_OF_DEALS";

const defaultPlatform = "crossboundary_pa";

const defaultFilters = {
  industrySectors: [],
  dealRanges: [],
  regionalFocus: [],
  investingThemes: [],
  transactionType: "",
  dealStructure: "",
};

const defaultPagination = {
  total: 0,
  page: 1,
  count: 10,
};

const state = {
  deals: null,
  filters: { ...defaultFilters },
  pagination: { ...defaultPagination },
  hits: 0,
  selectedDeal: {},
  platform: defaultPlatform,
  dealType: "deal",
  isFilterOpen: false,
  totalNumberOfDeals: 0,
};

const mutations = {
  [SET_DEALS](state, { deals, hits }) {
    state.deals = deals;
    state.hits = hits;
    state.pagination.total = hits;
  },
  [SET_PLATFORM](state, platform) {
    state.platform = platform;
  },
  [SET_FILTERS](state, filters) {
    state.filters = filters;
  },
  [SET_PAGINATION](state, pagination) {
    state.pagination = pagination;
  },
  [SET_SELECTED_DEAL](state, deal) {
    state.selectedDeal = deal;
  },
  [SET_DEAL_TYPE](state, dealType) {
    state.dealType = dealType;
  },
  [SET_FILTER_OPEN](state, isOpen) {
    state.isFilterOpen = isOpen;
  },
  [CLEANUP](state) {
    state.pagination = { ...defaultPagination };
    state.filters = { ...defaultFilters };
    state.platform = defaultPlatform;
    state.deals = null;
    state.selectDeal = {};
    state.isFilterOpen = false;
  },
  [SET_TOTAL_NUMBER_OF_DEALS](state, numberOfDeals) {
    state.totalNumberOfDeals = numberOfDeals;
  },
};

const getters = {
  list: (state) => state.deals,
  hits: (state) => state.hits,
  pagination: (state) => state.pagination,
  filters: (state) => state.filters,
  isFilterOpen: (state) => state.isFilterOpen,
  dealType: (state) => state.dealType,
  selectedDeal: (state) => state.selectedDeal,
  selectedDealStatus: (state, getters, rootState, rootGetters) => {
    const deal = getters.selectedDeal;
    if (deal.archivedAt) {
      return "archived";
    }
    const userDeals = rootGetters["account/deals"];
    const userDeal = userDeals.find((d) => d._id === deal._id);
    if (userDeal) {
      if (userDeal.archivedAt) {
        return "archived";
      }
      return "selected";
    }
    return "available";
  },
  getDealStatus: (state, getters, rootState, rootGetters) => (id) => {
    const userDeals = rootGetters["account/deals"];
    const userDeal = userDeals.find((d) => d._id === id);
    if (userDeal) {
      if (userDeal.archivedAt) {
        return "archived";
      }
      return "selected";
    }
    return "available";
  },
  totalNumberOfDeals: (state) => state.totalNumberOfDeals,
};

const actions = {
  selectDeal({ commit }, id) {
    return axios
      .post("/search2", {
        kind: ["dealroom_interest"],
        query: {
          bool: {
            must: {
              term: {
                _id: id,
              },
            },
          },
        },
        count: 1,
      })
      .then((resp) => {
        commit(SET_SELECTED_DEAL, resp.data.data[0]);
        return (resp.data || {}).data;
      });
  },
  updateFilters({ commit, state, dispatch }, selections) {
    const filters = { ...state.filters, ...selections };
    commit("SET_FILTERS", filters);
    return dispatch("list");
  },
  clearPlatform({ commit }) {
    commit(SET_PAGINATION, { ...defaultPagination });
    commit(SET_PLATFORM, defaultPlatform);
  },
  clearAll({ commit }) {
    commit(CLEANUP);
  },
  openFilter({ commit }, payload) {
    commit(SET_FILTER_OPEN, payload);
  },
  setPlatform({ commit }, payload) {
    commit(SET_PLATFORM, payload);
  },
  setDealType({ commit }, payload) {
    commit(SET_DEAL_TYPE, payload);
  },
  setPaginationPage({ commit, state, dispatch }, page) {
    const payload = { ...state.pagination };
    payload.page = page;
    commit(SET_PAGINATION, payload);
    return dispatch("list");
  },
  list({ commit, state, getters, rootGetters }) {
    const interest = rootGetters["account/interest"];
    const selections = _.cloneDeep(getters.filters);
    const pagination = getters.pagination;
    const pageNum = (pagination || {}).page || 1;
    const pageCount = (pagination || {}).count || 10;
    const offset = (pageNum - 1) * pageCount;
    const dealType = state.dealType;

    const filters = Object.keys(selections).reduce((result, key) => {
      if (!selections[key] || selections[key].length === 0) return result;
      if (
        [].concat(selections[key] && key !== "transactionType").includes("all")
      )
        return result; // all means any

      result[key] = selections[key];
      return result;
    }, {});

    let platforms = state.platform || "crossboundary_pa";

    if (dealType !== "fund") {
      const structure = (filters.dealStructure || "").toLowerCase();
      if (structure === "debt") filters.isDebtInvestment = true;
      if (structure === "equity") filters.isEquityInvestment = true;
      if (structure === "dual: debt and equity") {
        filters.isDebtInvestment = true;
        filters.isEquityInvestment = true;
      }
    }

    delete filters.dealStructure;

    if (["all", "trade"].includes(filters.transactionType)) {
      filters.isTradeDeal = true;
    }
    if (["all", "investment"].includes(filters.transactionType)) {
      filters.isEquityInvestment = true;
    }
    // if (["all", "lpEquity"].includes(filters.transactionType)) {
    //   filters.isLpEquity = true;
    // }
    if (dealType === "fund") {
      filters.isLpEquity = true;
    }

    delete filters.transactionType;

    filters.platforms = platforms;

    const mustRules = Object.keys(filters).map((key) => ({
      bool: {
        should: _.uniq([].concat(filters[key])).map((value) => ({
          term: { [`${key}.lowerKeyword`]: value },
        })),
      },
    }));
    if (dealType === "deal") {
      mustRules.push({
        bool: {
          should: [
            {
              term: {
                isLpEquity: false,
              },
            },
            {
              bool: {
                must_not: {
                  exists: {
                    field: "isLpEquity",
                  },
                },
              },
            },
          ],
        },
      });
    }

    return axios
      .post("/search2", {
        kind: ["dealroom_interest"],
        offset,
        query: {
          bool: {
            must_not: {
              terms: {
                _id: ((interest || {}).deals || []).map((d) => d._id),
              },
            },
            must: mustRules,
          },
        },
        count: pageCount,
        sort: [{ _lastUpdated: { order: "desc" } }],
      })
      .then((resp) => {
        commit(SET_DEALS, {
          deals: _.get(resp, "data.data"),
          hits: _.get(resp, "data.pagination.total"),
        });
        return (resp.data || {}).data;
      });
  },
  setTotalNumberOfDeals({ commit, rootGetters, state }) {
    let platforms = state.platform || "crossboundary_pa";
    const interest = rootGetters["account/interest"];
    const dealType = state.dealType;
    const filters = {};
    const mustRules = [
      {
        bool: {
          should: { term: { "platforms.lowerKeyword": platforms } },
        },
      },
    ];

    if (dealType === "fund") {
      mustRules.push({
        bool: {
          should: [
            {
              term: {
                isLpEquity: true,
              },
            },
          ],
        },
      });
    }
    if (dealType === "deal") {
      mustRules.push({
        bool: {
          should: [
            {
              term: {
                isLpEquity: false,
              },
            },
            {
              bool: {
                must_not: {
                  exists: {
                    field: "isLpEquity",
                  },
                },
              },
            },
          ],
        },
      });
    }
    return axios
      .post("/search2", {
        kind: ["dealroom_interest"],
        query: {
          bool: {
            must_not: {
              terms: {
                _id: ((interest || {}).deals || []).map((d) => d._id),
              },
            },
            must: mustRules,
          },
        },
        count: 1,
      })
      .then((resp) => {
        commit(SET_TOTAL_NUMBER_OF_DEALS, _.get(resp, "data.pagination.total"));
      });
  },
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
};
