import axios from "@/core/services/axios";
import _ from "lodash";

const SET_INTEREST = "SET_INTEREST";
const SET_PRIMARY_ROLE = "SET_PRIMARY_ROLE";

const state = {
  interest: {
    deals: [],
    other: {
      primaryRole: "investor",
    },
  },
};

const mutations = {
  [SET_INTEREST](state, interest) {
    state.interest = interest;
  },
  [SET_PRIMARY_ROLE](state, role) {
    state.interest.other.primaryRole = role;
  },
};

const actions = {
  update({ commit }, payload) {
    return axios
      .put(`/dealroom-interest/${payload._id}`, payload)
      .then((resp) => {
        commit(SET_INTEREST, resp.data);
        return resp.data;
      });
  },
  get({ commit, getters, dispatch }) {
    return axios
      .get("/dealroom-interests?platform=crossboundary")
      .then((resp) => {
        const interest = (resp.data || { items: [] }).items[0];
        if (interest) {
          if (!interest.other) {
            const primaryRole = getters.primaryRole;
            interest.other = { primaryRole };
          }
          commit(SET_INTEREST, interest);
          const filters = getters.savedDealFilters;
          const filtersAreEmpty = Object.values(filters).every((filter) =>
            _.isEmpty(filter)
          );
          if (!filtersAreEmpty) {
            dispatch("deals/updateFilters", filters, { root: true });
          }
        }
        return interest;
      });
  },
  create({ commit }, interest) {
    return axios.post("/dealroom-interests", interest).then((response) => {
      const interest = response.data;
      commit(SET_INTEREST, interest);
      return interest;
    });
  },
  ensure({ dispatch, getters, rootGetters }) {
    const user = rootGetters["user/currentUser"];

    return dispatch("get").then((result) => {
      if (result) return result;

      const primaryRole = getters.primaryRole;

      return dispatch("create", {
        company: {
          name: user.organisation || "<not specified>",
          website: "<not specified>",
          baseCountry: "<not specified>",
        },
        contactPerson: {
          givenName: user.firstName,
          familyName: user.lastName,
          email: user.email,
          telephone: "<not specified>",
        },
        investorType: "private",
        platform: "crossboundary",
        userId: user.userId,
        other: { primaryRole },
      });
    });
  },
  setPrimaryRole({ commit }, role) {
    commit(SET_PRIMARY_ROLE, role);
  },
};

const getters = {
  interest: (state) => state.interest,
  primaryRole: (state) =>
    _.get(state.interest, "other.primaryRole") || "investor",
  deals: (state) => state.interest?.deals || [],
  selectedDeals: (state, getters) => getters.deals.filter((d) => !d.archivedAt),
  archivedDeals: (state, getters) => getters.deals.filter((d) => d.archivedAt),
  savedDealFilters: (state, getters) => {
    const interest = getters.interest;
    return {
      ..._.pick(interest, [
        "industrySectors",
        "dealRanges",
        "regionalFocus",
        "dealStructure",
        "investingThemes",
      ]),
    };
  },
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
};
