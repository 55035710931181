function cleanData(data) {
  const newData = [];
  for (let ii = 0; ii < data.length; ii++) {
    const currentObject = data[ii];
    const newObject = {};
    for (let jj in currentObject) {
      var reg = /^\d+$/;
      const isPropertyValid = reg.test(jj);
      if (isPropertyValid) {
        newObject[jj] = currentObject[jj];
      }
    }
    newData.push(newObject);
  }

  return newData;
}

function groupData(data) {
  const fieldName = [
    "name",
    "profile",
    "sector",
    "focusArea",
    "ticketSizeStrict",
    "ticketSize",
    "investmentInstrument",
    "website",
    "north",
    "east",
    "west",
    "south",
    "central",
    "baseCountry",
    "logo",
  ];

  const newData = new Array(Object.keys(data[0]).length).fill().map(() => {
    return {};
  });

  for (let ii = 0; ii < data.length; ii++) {
    const currentObject = data[ii];
    const currentFieldName = fieldName[ii];
    for (let jj in currentObject) {
      newData[jj - 1][currentFieldName] = currentObject[jj];
    }
  }

  return newData;
}

function fieldsFormatting(data) {
  const newData = data.map((item) => {
    const itemWithRegionalFocusFormatted = regionalFocusFormatting(item);

    return itemWithRegionalFocusFormatted;
  });

  return newData;
}

function regionalFocusFormatting(item) {
  const newItem = {};
  const listOfPosibleRegions = ["central", "east", "north", "south", "west"];
  const regionalFocus = [];

  for (let ii in item) {
    if (listOfPosibleRegions.includes(ii)) {
      if (item[ii] === "TRUE") {
        regionalFocus.push(ii);
      }
    } else {
      newItem[ii] = item[ii];
    }
  }
  newItem.regionalFocus = regionalFocus;

  return newItem;
}

export default (data) => fieldsFormatting(groupData(cleanData(data)));
