import _ from "lodash";
import axios from "@/core/services/axios";

import {
  SET_SUBMISSIONS,
  SET_SUBMISSION_DATA,
  SET_SUBMISSION_ID,
  SET_INTERNAL_SUBMISSIONS,
  SET_COMPANY_DATA,
  SET_SUBMISSION_DRAFT,
} from "./mutation-types";

const state = {
  submissions: [],
  latestSubmission: {},
  selectedSubmissionId: "new",
  selectedCompanyData: {},
  internalSubmissions: [],
  isInternalLoaded: false,
  draft: null,
};

const mutations = {
  [SET_SUBMISSIONS](state, data) {
    state.submissions = data;
  },
  [SET_SUBMISSION_DATA](state, data) {
    state.latestSubmission = data;
  },
  [SET_SUBMISSION_ID](state, payload) {
    state.selectedSubmissionId = payload;
  },
  [SET_COMPANY_DATA](state, payload) {
    state.selectedCompanyData = payload;
  },
  [SET_INTERNAL_SUBMISSIONS](state, payload) {
    state.internalSubmissions = payload;
    state.isInternalLoaded = true;
  },
  [SET_SUBMISSION_DRAFT](state, payload) {
    state.draft = payload;
  },
};

const actions = {
  setSubmissionId({ commit }, submissionId) {
    commit(SET_SUBMISSION_ID, submissionId);
  },
  clearSubmission({ commit }) {
    commit(SET_SUBMISSION_ID, "new");
    commit(SET_SUBMISSION_DATA, {});
    commit(SET_SUBMISSION_DRAFT, null);
  },
  saveSubmissionDraft({ commit }, payload) {
    commit(SET_SUBMISSION_DRAFT, payload);
  },
  getProposals({ commit, dispatch }) {
    return axios.get("/profiles/dealbook-submissions").then((res) => {
      const submissions = _.get(res, "data.items", [])
        .map((item) => {
          const obj = {
            ...item.lastSubmission,
            formatted: item.lastSubmissionFormatted,
            companyId: item.companyId,
            internalInterestId: item.interestId,
          };
          const opportunityCompanyId = _.get(
            obj,
            "formatted.company.opportunityCompanyId"
          );
          if (opportunityCompanyId) {
            obj.opportunityCompanyId = opportunityCompanyId;
          }
          return obj;
        })
        .filter((item) => {
          return item.platforms.includes("crossboundary");
        });
      const ids = submissions
        .map((s) => s.internalInterestId)
        .filter((id) => id && id.length === 24);
      if (ids.length) {
        dispatch("getProposalsInternals", ids);
      }
      commit(SET_SUBMISSIONS, submissions);
    });
  },
  getProposalsInternals({ commit }, ids) {
    return axios
      .get("/profiles/entities-multi?ids=" + ids.join())
      .then((res) => {
        commit(SET_INTERNAL_SUBMISSIONS, res.data.items);
      });
  },
  getSubmission({ commit }, submissionId) {
    return axios
      .get("/profiles/dealbook-submissions/" + submissionId)
      .then((res) => {
        const data = res.data;
        if (_.isEmpty(data)) {
          return "forbidden";
        }
        if (Object.keys(data).length) {
          if (data.dealroomInterestId) {
            commit(SET_SUBMISSION_ID, data.dealroomInterestId);
          }
          // const normalizedData = helpers.normalizeDataForForm(data);
          commit(SET_SUBMISSION_DATA, data);
        }
      });
  },
  updateSubmission({ dispatch }, payload) {
    const normalized = _.cloneDeep(payload);
    // const fundYear = normalized.interest.fundYear;
    // const fundMonth = normalized.interest.fundMonth;
    // normalized.interest.fundDate = helpers.formatFundDate(fundYear, fundMonth);
    normalized.dealroomInterestId = "new";
    return axios
      .patch("/profiles/dealbook-submissions", normalized)
      .then((res) => {
        dispatch("clearSubmission");
      });
  },
  changeSubmissionStatus({ commit, getters }, payload) {
    const { deal, state, reason, type } = payload;
    const proposal = {
      ...deal.formatted,
      dealroomInterestId: deal.dealroomInterestId,
      isComplete: deal.isComplete,
      type,
      step: 2,
    };
    proposal.interest.submissionState = state;
    proposal.interest.stateReason = reason;

    return axios
      .patch("/profiles/dealbook-submissions", proposal)
      .then((res) => {
        let submissions = getters.submissions.filter((s) => {
          if (s.dealroomInterestId === proposal.dealroomInterestId) {
            return false;
          }
          return true;
        });
        submissions = [{ ...res.data, formatted: proposal }].concat(
          submissions
        );
        commit(SET_SUBMISSIONS, submissions);
      });
  },
  selectSubmissionCompany({ commit }, payload) {
    commit(SET_COMPANY_DATA, payload);
  },
};

const getters = {
  latestSubmission: (state) => state.latestSubmission,
  selectedSubmissionId: (state) => state.selectedSubmissionId,
  selectedCompanyData: (state) => state.selectedCompanyData,
  selectedCompanySubmissions: (state, getters) => {
    const selectedCompanyId = _.get(
      getters.selectedCompanyData,
      "selectedCompany._id"
    );
    if (selectedCompanyId) {
      return getters.submissionsByCompany(
        getters.selectedCompanyData.selectedCompany
      );
    }
    return [];
  },
  submissions: (state) => state.submissions,
  submissionDraft: (state) => state.draft,
  internalSubmissions: (state) => state.internalSubmissions,
  isInternalLoaded: (state) => state.isInternalLoaded,
  userCompanies: (state) => {
    const allCompanies = state.submissions
      .filter((submission) => {
        if (submission.type === "dealbook.submission") {
          const isProfileExists = state.submissions.find((i) => {
            return (
              i.opportunityCompanyId === submission.opportunityCompanyId &&
              i.type === "company.profile"
            );
          });
          if (isProfileExists) {
            return false;
          }
          return true;
        }
        return true;
      })
      .map((submission) => {
        if (submission.opportunityCompanyId) {
          const company = {
            ...submission.formatted.company,
            _id: submission.companyId,
            companyProfileId: null,
            opportunityCompanyId: submission.opportunityCompanyId,
          };
          if (
            submission.type === "company.profile" &&
            submission.dealroomInterestId
          ) {
            company.companyProfileId = submission.dealroomInterestId;
          }
          return company;
        }
      })
      .filter((v) => v);
    return _.uniqBy(allCompanies, "opportunityCompanyId");
  },
  submissionsByCompany: (state, getters) => (company) => {
    return getters.submissions.filter((item) => {
      if (company._id && item.companyId) {
        return item.companyId === company._id;
      }
      if (company.displayName) {
        return item.companyDisplayName === company.displayName;
      }
    });
  },
  previousSubmission: (state, getters) => {
    const companyData = getters.selectedCompanyData;
    if (companyData.isNewCompany) {
      return null;
    }
    const submissions = getters.submissionsByCompany(
      companyData.selectedCompany
    );
    if (submissions.length) {
      return submissions[0];
    }
    return null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
