import Swal from "sweetalert2/dist/sweetalert2.min.js";

const actions = {
  showSuccessMessage(_props, { text, icon, customClass, confirmButtonText }) {
    return Swal.fire({
      text: text,
      icon: icon || "success",
      buttonsStyling: false,
      confirmButtonText: confirmButtonText,
      customClass: customClass || {
        confirmButton: "btn fw-semobold btn-light-primary",
      },
    });
  },
  showErrorMessage(_props, { mainText, icon, customClass, buttonText }) {
    return Swal.fire({
      text: mainText,
      icon: icon || "error",
      buttonsStyling: false,
      confirmButtonText: buttonText,
      customClass: customClass || {
        confirmButton: "btn fw-semobold btn-light-danger",
      },
    });
  },
};

export default {
  namespaced: true,
  actions,
};
