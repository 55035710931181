import _ from "lodash";
import axios from "@/core/services/axios";

const SET_INTERMEDIARY_LIST = "SET_INTERMEDIARY_LIST";
const SET_CURRENT_INTERMEDIARY = "SET_CURRENT_INTERMEDIARY";
const SET_FILTERS = "SET_FILTERS";
const SET_FILTER_OPEN = "SET_FILTER_OPEN";
const CLEANUP = "CLEANUP";

const state = {
  intermediaryList: [],
  currentIntermediary: {},
  filters: null,
  isFilterOpen: false,
};

const mutations = {
  [SET_INTERMEDIARY_LIST](state, payload) {
    state.intermediaryList = payload;
  },
  [SET_CURRENT_INTERMEDIARY](state, payload) {
    state.currentIntermediary = payload;
  },
  [SET_FILTERS](state, payload) {
    state.filters = payload;
  },
  [SET_FILTER_OPEN](state, isOpen) {
    state.isFilterOpen = isOpen;
  },
  [CLEANUP](state) {
    state.filters = null;
    state.isFilterOpen = false;
  },
};

const actions = {
  fetchIntermediaries({ commit }) {
    return axios.get("/data-report/client/pa-intermediaries").then((res) => {
      const intermediaryList = _.get(res, "data.intermediaries") || [];
      commit(SET_INTERMEDIARY_LIST, intermediaryList);
    });
  },
  async setCurrentIntermediary({ commit, state, dispatch }, payload) {
    if (!state.intermediaryList.length) {
      await dispatch("fetchIntermediaries");
    }
    const item = state.intermediaryList.filter(
      (intermediary) => intermediary._id === payload
    );
    commit(SET_CURRENT_INTERMEDIARY, item[0]);
  },
  setFilters({ commit }, payload) {
    commit(SET_FILTERS, payload);
  },
  openFilter({ commit }, payload) {
    commit(SET_FILTER_OPEN, payload);
  },
  clearAll({ commit }) {
    commit(CLEANUP);
  },
};

const getters = {
  getIntermediaryList: (state) => {
    const filters = state.filters;
    const intermediaryList = state.intermediaryList;
    if (!filters) {
      return intermediaryList;
    }

    let filteredList = intermediaryList;
    for (let ii in filters) {
      if (typeof filters[ii] === "string") {
        filteredList = filteredList.filter((item) => {
          if (Object.hasOwnProperty.call(item, ii)) {
            if (typeof filters[ii] === "string") {
              const filter = filters[ii].toLowerCase();
              const element = item[ii].toLowerCase();

              return element.includes(filter);
            }
          }
        });
      }
      if (Array.isArray(filters[ii])) {
        filteredList = filteredList.filter((item) => {
          let isEqual = true;
          filters[ii].forEach((element) => {
            if (!item[ii]?.includes(element)) {
              isEqual = false;
            }
          });
          return isEqual;
        });
      }
    }

    return filteredList;
  },
  getCurrentIntermediary: (state) => state.currentIntermediary,
  getFilters: (state) => state.filters,
  getTotalIntermediarys: (state) => state.intermediaryList,
  isFilterOpen: (state) => state.isFilterOpen,
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
};
