import { createStore } from "vuex";
import { config } from "vuex-module-decorators";
import axios from "@/core/services/axios";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import ConfigModule from "@/store/modules/ConfigModule";
import ThemeModeModule from "@/store/modules/ThemeModeModule";
import UserModule from "@/store/modules/user/user";
import TokensModule from "asokoinsight-tokens/src/vuex/tokens";
import messagesModule from "@/store/modules/messages/messages";
import dealsModule from "@/store/modules/deals/deals";
import accountModule from "@/store/modules/account/account";
import intermediaryModule from "@/store/modules/intermediary/intermediary";
import investorModule from "@/store/modules/investor/investor";
import investeeModule from "@/store/modules/investee/investee";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    ConfigModule,
    ThemeModeModule,
    user: UserModule,
    tokens: TokensModule,
    messages: messagesModule,
    deals: dealsModule,
    account: accountModule,
    intermediary: intermediaryModule,
    investor: investorModule,
    investee: investeeModule,
  },
});

store.$http = axios;

export default store;
