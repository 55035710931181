import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/join",
    component: () => import("@/layouts/home-layout/HomeLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/join",
        name: "join",
        component: () => import("@/views/landing-page.vue"),
        meta: {
          pageTitle: "Join",
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    children: [
      {
        path: "/investment/deals",
        name: "deals",
        component: () => import("@/views/investor/deals-page.vue"),
        meta: {
          pageTitle: "Available deals",
          breadcrumbs: ["Available deals"],
          promos: ["booklet"],
        },
      },
      {
        path: "/investment/deals/:id",
        name: "deal-page",
        component: () => import("@/views/investor/deal-page.vue"),
        meta: {
          pageTitle: "Deal details",
          breadcrumbs: ["Deal details"],
        },
      },
      {
        path: "/investment/funds",
        name: "funds",
        component: () => import("@/views/investor/funds-page.vue"),
        meta: {
          pageTitle: "Fund Investments",
          breadcrumbs: ["Fund Investments"],
          promos: ["booklet"],
        },
      },
      {
        path: "/investment/selected",
        name: "selected-deals",
        component: () => import("@/views/investor/selected-deals-page.vue"),
        meta: {
          pageTitle: "Selected deals",
          breadcrumbs: ["Selected deals"],
          promos: ["booklet"],
        },
      },
      {
        path: "/investment/archived",
        name: "archived-deals",
        component: () => import("@/views/investor/archived-page.vue"),
        meta: {
          pageTitle: "Archived deals",
          breadcrumbs: ["Archived deals"],
          promos: ["booklet"],
        },
      },
    ],
  },
  {
    path: "/proposal",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    children: [
      {
        path: "new",
        name: "new-deal",
        component: () => import("@/views/investee/add-deal-page.vue"),
        meta: {
          pageTitle: "Add deal",
          breadcrumbs: ["Add deal"],
        },
      },
      {
        path: "deals",
        name: "your-deals",
        component: () => import("@/custom-components/investee/deals-page.vue"),
        meta: {
          pageTitle: "Your deals",
          breadcrumbs: ["Your deals"],
        },
      },
      {
        path: "deals/:id",
        name: "deal-details",
        component: () => import("@/custom-components/investee/deal-page.vue"),
        meta: {
          pageTitle: "Deal details",
          breadcrumbs: ["Deal details"],
        },
      },
    ],
  },
  {
    path: "/account",
    redirect: "/account/settings/contact",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    children: [
      {
        path: "settings",
        name: "account-settings",
        redirect: "/account/settings/contact",
        component: () => import("@/views/account/settings-view.vue"),
        children: [
          {
            path: "contact",
            name: "account-contact",
            component: () => import("@/views/account/contact-view.vue"),
            meta: {
              pageTitle: "Contact person & Company",
              breadcrumbs: ["Contact person & Company"],
            },
          },
          {
            path: "your-role",
            name: "your-role",
            component: () => import("@/views/account/your-role.vue"),
            meta: {
              pageTitle: "Your deal room role",
              breadcrumbs: ["Your deal room role"],
            },
          },
          {
            path: "areas",
            name: "areas-settings",
            component: () => import("@/views/account/areas-settings.vue"),
            meta: {
              pageTitle: "Areas of interest",
              breadcrumbs: ["Areas of interest"],
            },
          },
        ],
      },
    ],
  },
  {
    path: "/investor",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    children: [
      {
        path: "/investor/list",
        name: "investor-list",
        component: () => import("@/views/investor/list-page.vue"),
        meta: {
          pageTitle: "List of investor",
          breadcrumbs: ["List of investor"],
        },
      },
      {
        path: "/investor/detail/:id",
        name: "investor-detail",
        component: () => import("@/views/investor/detail-page.vue"),
        meta: {
          pageTitle: "Investor details",
          breadcrumbs: ["Investor details"],
        },
      },
      {
        path: "/investor/new",
        name: "become-investor",
        component: () => import("@/views/investor/become-investor-page.vue"),
        meta: {
          pageTitle: "Join investors",
          breadcrumbs: ["Join investors"],
        },
      },
    ],
  },
  {
    path: "/intermediary",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    children: [
      {
        path: "/intermediary/list",
        name: "intermediary-list",
        component: () => import("@/views/intermediary/list-page.vue"),
        meta: {
          pageTitle: "List of intermediary",
          breadcrumbs: ["List of intermediary"],
        },
      },
      {
        path: "/intermediary/detail/:id",
        name: "intermediary-detail",
        component: () => import("@/views/intermediary/detail-page.vue"),
        meta: {
          pageTitle: "Intermediary details",
          breadcrumbs: ["Intermediary details"],
        },
      },
      {
        path: "/intermediary/new",
        name: "become-intermediary",
        component: () =>
          import("@/views/intermediary/become-intermediary-page.vue"),
        meta: {
          pageTitle: "Join intermediaries",
          breadcrumbs: ["Join intermediaries"],
        },
      },
    ],
  },
  {
    path: "/sectors",
    component: () => import("@/layouts/main-layout/MainLayoutGuest.vue"),
    children: [
      {
        path: "/sectors",
        name: "sectors-landing",
        component: () => import("@/views/sectors/sectors-landing.vue"),
        meta: {
          pageTitle: "Sectors",
          breadcrumbs: ["Sectors"],
        },
      },
      {
        path: "/sectors/healthcare",
        name: "healthcare",
        component: () => import("@/views/sectors/healthcare-page.vue"),
        meta: {
          pageTitle: "Healthcare",
          breadcrumbs: ["Healthcare"],
        },
      },
      {
        path: "/sectors/connectivity",
        name: "connectivity",
        component: () => import("@/views/sectors/connectivity-page.vue"),
        meta: {
          pageTitle: "Connectivity",
          breadcrumbs: ["Connectivity"],
        },
      },
      {
        path: "/sectors/creative",
        name: "creative",
        component: () => import("@/views/sectors/creative-page.vue"),
        meta: {
          pageTitle: "Creative Industries",
          breadcrumbs: ["Creative Industries"],
        },
      },
    ],
  },
  {
    path: "/landing",
    component: () => import("@/layouts/main-layout/MainLayoutGuest.vue"),
    children: [
      {
        path: "africa-booklet",
        name: "africa-booklet",
        component: () => import("@/views/landings/africa-booklet.vue"),
        meta: {
          pageTitle: "Africa Booklet",
          breadcrumbs: ["Africa Booklet"],
        },
      },
    ],
  },
  {
    path: "/terms",
    component: () => import("@/layouts/main-layout/MainLayoutGuest.vue"),
    children: [
      {
        path: "/terms",
        name: "terms",
        component: () => import("@/views/general/terms-page.vue"),
        meta: {
          pageTitle: "Terms and conditions",
          breadcrumbs: ["Terms and conditions"],
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        meta: {
          pageTitle: "Sign In",
        },
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
        meta: {
          pageTitle: "Sign Up",
        },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
        meta: {
          pageTitle: "Password reset",
        },
      },
      {
        path: "/new-password",
        name: "new-password",
        component: () =>
          import(
            "@/views/crafted/authentication/basic-flow/SetNewPassword.vue"
          ),
        meta: {
          pageTitle: "New Password",
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to) => {
  // current page view title
  document.title = `${to.meta.pageTitle} - ${process.env.VUE_APP_NAME}`;

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

export default router;
