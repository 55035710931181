
import { defineComponent, nextTick, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/keenthemes";
import GovAlert from "./custom-components/common/gov-alert.vue";

export default defineComponent({
  name: "app",
  components: {
    GovAlert,
  },
  setup() {
    const store = useStore();
    const isTokenChecked = computed(() => {
      return store.getters["tokens/isTokenChecked"];
    });

    onMounted(async () => {
      /**
       *  Sets a mode from configuration
       */
      store.dispatch(Actions.SET_THEME_MODE_ACTION, "light");
      await store.dispatch("user/checkAndRefresh");

      nextTick(() => {
        initializeComponents();

        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      });
    });
    return {
      isTokenChecked,
    };
  },
});
